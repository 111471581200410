import './App.css';
import ConsideringPage from './ConsideringPage';
import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          welcome to ramblechain
        </p>
        <ConsideringPage />
      </header>
    </div>
  );
}

export default App;
