import React, { useState, useEffect} from 'react';
import './ConsideringPage.css';

// cache score superset from featurestore
function ConsideringPage(props) {
    const [ consideringItems, setConsideringItems ] = useState([]);

    // this is componentDidMount
    useEffect(() => {
        const testResponse = {
            "content": [
                {
                    "id": "afJ3b0",
                    "thought": "Eventually you run out of cutting edge feeds. Over time, information which:\n 1. You have not seen yet, and\n 2. Is available to consume. \nGets older as feeds are exhausted.",
                    "author": "sean",
                    "timestamp": "2016-07-01T00:00:00Z",
                    "tags": ["thoughts", "information", "feeds", "exhausted"],
                    "scores": {
                        "sentiment": 0.1,
                        "abstact": 0.7,
                        "useful": 0.2,
                        "recency": 0.8
                    },
                    "documentIndex": ["120"]
                },
                {
                    "id": "afJ3b1",
                    "thought": "All projects die",
                    "author": "sean",
                    "timestamp": "2016-07-01T00:00:00Z",
                    "tags": ["thoughts", "projects", "death"],
                    "scores": {
                        "sentiment": 0.3,
                        "abstact": 0.7,
                        "useful": 0.2,
                        "recency": 0.3
                    },
                    "documentIndex": ["328"]
                },
                {
                    "id": "afJ3b3",
                    "thought": "When you die, you will be forgotten. Your projects will die. Your thoughts will die. Your memories will die. Your legacy will die. Your name will die. Your body will die. Your soul will die. Your spirit will die. Your existence will die. Your life \n-copilot, https://copilot.microsoft.com/",
                    "author": "sean",
                    "timestamp": "2016-07-01T00:00:00Z",
                    "tags": ["forget", "dementia", "death"],
                    "links": ["https://copilot.microsoft.com/"],
                    "scores": {
                        "sentiment": 0.1,
                        "abstact": 0.5,
                        "useful": 0.1,
                        "recency": 0.6
                    },
                    "documentIndex": ["374"]
                },
                {
                    "id": "afJ3b4",
                    "thought": "I still believe that our ideas from void apply - we should work towards removing the necessity to select _where_ to put whatever the user wants to enter. I think it should create a PR after you enter something to wherever in the novel the search algo believes the new entry belongs. Then you can also further revise / edit in the PR UI itself. I think each “repository” / book could be given some explicit metadata from the user, for instance:\n- settings: set of all settings in book, \n> setting has location, characters, description\n- characters: set of all characters in book\n> character has name, description\n- chapters: set of all chapters in book\n> chapter has name, description\nThen we can match any given input text with this metadata to find a place to append it.",
                    "author": "sean",
                    "timestamp": "2016-07-01T00:00:00Z",
                    "tags": ["thoughts", "void", "metadata", "search", "algorithms", "repositories", "books", "settings", "characters", "chapters"],
                    "scores": {
                        "sentiment": 0.5,
                        "abstact": 0.7,
                        "useful": 0.7,
                        "recency": 0.9
                    },
                    "documentIndex": ["64"]
                }
            ]
        }

        setConsideringItems(testResponse['content']);
        console.log("consideringItems: ", consideringItems);

    },[consideringItems]);

    /*
    // if we need better docs for fetching, this looks great: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    var requestConsideringItemsByUser = async (userID) => {

        const response = 
        await fetch('http://our-api.com/considering?userId=' + userID).then((response) => {
            if (response.status >= 400 && response.status < 600) {
                throw new Error("Bad response from server");
            }
            return response.json();
        }).then((returnedResponse) => {
            return returnedResponse.json();
        }).catch((error) => {
            console.error("Unable to fetch data:", error);
            console.log("fetching test data instead");
            const consideringResponse = fetchJSONTestData();
        });
    }

    const getExample = async () => {
        const response = await fetch('http://example.com/movies.json');
        const myJson = await response.json(); //extract JSON from the http response
        // do something with myJson
    }

    const postExample = async () => {
        const response = await fetch('http://example.com/movies.json', {
            method: 'POST',
            body: "currently a string body to post, can also be object", // string or object
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const myJson = await response.json(); //extract JSON from the http response
        // do something with myJson
    }
    */

    return (
        <div>
            <h3>considering</h3>
            <div>
                {consideringItems && consideringItems.map((item) => {
                    return <p className='consideringItem'>{item['thought']}</p>
                })}
            </div>
        </div>
    )
}

export default ConsideringPage;